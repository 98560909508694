import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Task from './components/Task/Task';
import Feedback from './components/Feedback/Feedback';
import Reset from './components/Login/Reset';
import Agreement from './components/Login/Agreement';
import Privacy from './components/Login/Privacy';
import Docs from './components/Docs/Docs';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Register from './components/Login/Register';
import Validation from './components/Login/Validation';
import PDBViewer from './components/Task/PDBViewer';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import moment from "moment";
import Logo from './logo_black.svg';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

function UserInfo({ setUserBoxOpen }) {
  const [residue, setResidue] = React.useState(0);
  const [storage, setStorage] = React.useState(0);
  const [used, setUsed] = React.useState(0);
  // eslint-disable-next-line
  const [updateTime, setUpdateTime] = React.useState(0);

  React.useEffect(() => {
    const message = new FormData();
    fetch(`${process.env.REACT_APP_API_URL}/api/get_remain_resources`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('token'),
      },
      body: message,
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then(data => {
        setResidue(data["remain_task_limit"]);
        setUsed(data["disk_usage"]);
        setUpdateTime(data["update_time"])
        setStorage(data["disk_total"]);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });

  }, []);

  return (
    <Box>
      <Box component={'h1'} sx={{
        textAlign: 'center',
        color: '#517DFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <IconButton aria-label="delete" onClick={() => { setUserBoxOpen(false); }} >
          <ArrowForwardIosIcon />
        </IconButton>
        <span>
          {localStorage.getItem('username')}
        </span>
        <IconButton sx={{ opacity: '0' }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          color: '#8E8E8E',
          textAlign: 'center',
        }}
      >
        {/* TODO: user email */}
        {/* zhujl@shanghaitech.edu.cn */}
      </Box>

      <Box
        sx={{
          color: '#000000',
          fontSize: '22px',
          fontWeight: '500',
          marginTop: '40px',
        }}
      >
        Cumulative Performance
      </Box>

      <Box
        sx={{
          color: '#8E8E8E',
          fontSize: '18px',
          marginTop: '20px',
        }}
      >
        Available Amino Acids
      </Box>
      <Box
        sx={{
          color: '#517DFF',
          fontSize: '30px',
          fontWeight: '700',
          marginTop: '5px',
        }}
      >
        {
          residue > 0 ? residue + "%" : <AllInclusiveIcon fontSize='large' />
        }
      </Box>
      <Box
        sx={{
          color: '#000000',
          fontSize: '18px',
          fontWeight: '500',
          marginTop: '5px',
        }}
      >
        Refill on {moment.unix(updateTime).format('YYYY-MM-DD')}
      </Box>

      <Box
        sx={{
          color: '#8E8E8E',
          fontSize: '18px',
          marginTop: '25px',
        }}
      >
        Storage Occupied
      </Box>
      <Box
        sx={{
          color: '#517DFF',
          fontSize: '30px',
          fontWeight: '700',
          marginTop: '5px',
        }}
      >
        {used}
        /
        {
          storage > 0 ? storage + 'G' : <AllInclusiveIcon fontSize='small' />
        }
      </Box>
      <Box
        sx={{
          color: '#000000',
          fontSize: '18px',
          fontWeight: '500',
          marginTop: '5px',
          cursor: 'pointer',
          textDecoration: 'underline', // Add underline style
        }}
        onClick={() => { window.location.href = '/feedback'; }}
      >
        Contact for update
      </Box>
  
    </Box>
  );
}

function Main() {
  const username = localStorage.getItem('username');
  const firstLetter = username ? username.charAt(0).toUpperCase() : '';
  const location = window.location.pathname;
  const expire_time = localStorage.getItem('expire_time');
  const [userBoxOpen, setUserBoxOpen] = React.useState(false);
  const [locValue, setLocValue] = React.useState(0);

  React.useEffect(() => {
    if (location === '/') {
      setLocValue(0);
    } else if (location === '/task') {
      setLocValue(1);
    } else if (location === '/docs') {
      setLocValue(2);
    } else if (location === '/feedback') {
      setLocValue(3);
    }

    const interval = setInterval(() => {
      if (expire_time) {
        const now = new Date();
        const expireTime = new Date(expire_time);
        if (now > expireTime) {
          localStorage.clear();
          window.location.href = '/login';
        }
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [expire_time]);

  function handleLogin() {
    window.location.href = '/login';
  }

  function handleLogout() {
    localStorage.clear();
    window.location.href = '/';
  }

  function handleJump(event, newValue) {
    if (newValue === 0) {
      window.location.href = '/';
    }
    if (newValue === 1) {
      window.location.href = '/task';
    }
    if (newValue === 2) {
      window.location.href = '/docs';
    }
    if (newValue === 3) {
      window.location.href = '/feedback';
    }
  }

  return (
    <Box sx={{ display: 'flex', background: '#E5EEF8', }}>
      <CssBaseline />
      <Box position="fixed" sx={{
        backgroundColor: '#ffffff',
        zIndex: "4",
        color: '#000000',
        width: '100vw',
        fontFamily: 'Inter'
      }}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '1600px',
            margin: '0 auto',
          }}
        >
          <Box
            component={'img'}
            src={Logo}
            alt="logo"
            sx={{
              width: '200px',
              height: 'auto',
              cursor: 'pointer',
            }}
            onClick={() => window.location.href = '/'}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '15px',
            }}
          >
            <Tabs value={locValue} onChange={handleJump} aria-label="basic tabs example">
              <Tab label="Models" />
              <Tab label="Tasks" />
              <Tab label="Docs" />
              <Tab label="Contact Us" />
            </Tabs>

            {username ?
              <Box
              >
                <IconButton
                  aria-label="user"
                  size="large"
                  onClick={() => setUserBoxOpen(!userBoxOpen)}
                >
                  <Avatar>
                    {firstLetter}
                  </Avatar>
                </IconButton>
                <Box
                  sx={{
                    position: 'fixed',
                    top: '64px',
                    right: '10px',
                    backgroundColor: '#F7F7F7',
                    // maxWidth: '200px',
                    width: '350px',
                    display: (userBoxOpen ? 'flex' : 'none'),
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // borderRadius: '26px',
                    height: 'calc(100vh - 64px)',
                    padding: '10px 40px',
                  }}
                >
                  <UserInfo setUserBoxOpen={setUserBoxOpen} />
                  <Box
                    sx={{
                      margin: '10px',
                      // borderRadius: '26px',
                      // backgroundColor: '#fff',
                      // boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Button sx={{ mx: 1, color: '#8E8E8E', fontWeight: 800 }} onClick={handleLogout}>Logout</Button>
                  </Box>
                </Box>
              </Box>
              // <Button color="inherit" sx={{ mx: 1 }} onClick={handleLogout}>Logout</Button>
              :
              <Button color="inherit" sx={{ mx: 1 }} onClick={handleLogin}>Sign In</Button>
            }
          </Box>

        </Toolbar>
      </Box>
      <div style={{ position: 'relative', zIndex: location === '/viewer' ? '10' : '2' }}>
        <DrawerHeader />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/validation" element={<Validation />} />
            <Route path="/task" element={<Task />} />
            <Route path="/viewer" element={<PDBViewer />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/agreement" element={<Agreement />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/docs" element={<Docs />} />
          </Routes>
        </Router>
      </div>
    </Box>
  );
}


export default function App() {
  return (
    process.env.REACT_APP_ENV === "maintain" ?
      <div style={{
        textAlign: 'center',
        marginTop: '20px',
        color: '#3874cb',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <h1>Website is under maintenance</h1>
        <h2>We expect to resume platform services after October 18, 2024</h2>
        <h2>Please come back later</h2>
      </div>
      :
      <Main />
  );

}